import "./Signup.css";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CircularProgress } from "@mui/material";
import { baseURL } from "../../config";
import heroBg from "../../shared/images/home.png";
import styles from "../Hero/Hero.module.css";
import { useTranslation } from "react-i18next";

const DeleteAccount = () => {
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const [getotp, setgetotp] = useState(null);
  const [toggle, settoggle] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [resetLoading, setIsResendLoading] = useState(false);
  const [timer, setTimer] = useState(15);
  const [canResend, setCanResend] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const { t, _ } = useTranslation();
  const nav = useNavigate();
  const authKey = localStorage.getItem("Key");

  useEffect(() => {
    if (authKey) {
      nav("/");
    }
  }, [authKey]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const requestResendOtp = async () => {
    try {
      setIsResendLoading(true);
      const resp = await axios
        .get(`${baseURL}/send-otp/?phone=${phone}&login=true`)
        .then((response) => {
          if (response.data.is_exist) {
            console.log(response.data.data);
            setgetotp(response.data.data);
            settoggle(true);
            setIsResendLoading(false);
          } else {
            toast("User does not exist. Please register first.");
            setPhone("");
          }
          return response;
        });
    } catch (error) {
      setIsResendLoading(false);
      toast("Something went wrong");
    }
  };

  const handleResendOtp = async () => {
    console.log(canResend);
    if (canResend) {
      setResendCount((prevCount) => prevCount + 1);
      if (resendCount < 5) {
        toast("OTP send to your phone number");
        setTimer(20);
        requestResendOtp();
      } else if (resendCount >= 5 && resendCount < 10) {
        toast("OTP send to your phone number");
        setTimer(40);
        requestResendOtp();
      } else if (resendCount >= 10) {
        toast("You have requested multiple times. Please try again later.");
        setTimer(60);
        // requestResendOtp();
        // Possibly make a call to the backend to block the user for a day
        // ... blockUserForDay(); (you'd have to implement this)
      } else {
        toast("OTP send to your phone number");
        requestResendOtp();
      }
    }
  };

  const handleSendOtp = async () => {
    console.log(phone.length);
    if (phone.length >= 14 || phone.length < 10) {
      toast("Enter Valid Number");
      return;
    }
    try {
      setIsBtnLoading(true);
      const resp = await axios
        .get(`${baseURL}/send-otp/?phone=${phone}&login=true`)
        .then((response) => {
          if (response.data.is_exist) {
            console.log(response.data.data);
            setgetotp(response.data.data);
            settoggle(true);
            setCanResend(false);
            setTimer(15);
            toast("OTP send to your phone number");
          } else {
            toast("User does not exist. Please register first.");
            setPhone("");
          }
          setIsBtnLoading(false);
          return response;
        });
    } catch (error) {
      setIsBtnLoading(false);
      if (error.response.data) {
        toast(error.response.data.detail);
      } else {
        toast("Invalid Phone Number");
      }
    }
  };

 
  const handleVerify = async () => {
    console.log(otp.join(""));
    if (getotp !== otp.join("")) {
      toast("Invalid Otp");
      return;
    }
    const loginData = {
        phone: phone,
        fcm_key: "",
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        setIsBtnLoading(true);
        const resp = await axios.post(`${baseURL}/signin/`, loginData, options);
        if (resp.status === 200) {
          setToken(resp.data.access)
          setIsBtnLoading(false);
          setShowDeleteBtn(true);
        }
      } catch (error) {
        setIsBtnLoading(false);
        toast("Something went wrong, please try again later");
      }
  };

  const focusNextInput = (index) => {
    if (index < 3 && inputRefs.current) {
      inputRefs.current[index + 1].focus();
    }
  };

  const focusPrevInput = (index) => {
    if (index > 0 && inputRefs.current) {
      inputRefs.current[index - 1].focus();
    }
  };

  const updateOtpValue = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value) focusNextInput(index);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").trim();
    if (!/^\d{4}$/.test(pasteData)) return;

    const newOtp = pasteData.split("");
    setOtp(newOtp);
    focusNextInput(newOtp.length - 1);
  };

  const handleDeleteBtn = async () => {
    //api
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
    };
    console.log(token)
    try {
      setIsBtnLoading(true);
      const resp = await axios.post(`${baseURL}/delete-user/`, {},options);
      if (resp.status === 200) {
        setIsBtnLoading(false);
        toast("User Deleted SucessFully");
        nav("/");
        window.location.reload();
      }
    } catch (error) {
      setIsBtnLoading(false);
      toast("Something went wrong, please try again later");
      nav("/");
      window.location.reload();
    }

  };


  useEffect(() => {
    if (inputRefs.current && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  return (
    <div className={`${styles.container} justify-content-center`}>
      <div>
           <div className={`card ${styles.cardContainer} w-75 py-5`}>
           <div className="card-body">
             <h1 className="heading mb-5">Delete Your Account</h1>
             {showDeleteBtn?<>
             <p>Your account will be deleted. To recover your data, please contact the administrator.</p>
                <div className="mt-4 text-center">
                  <button
                    type="button"
                    onClick={handleDeleteBtn}
                    className="btn btn-sm btn-primary"
                    disabled={isBtnLoading}
                  >
                    {isBtnLoading ? (
                      <CircularProgress size={"1.2rem"} sx={{ color: "#FFF" }} />
                    ) : "Delete Account"}
                  </button>
                </div></>:
                <div className="my-3">
                {!toggle ? (
                  <>
                    <div className="form-group shadow-sm">
                      <PhoneInput
                        className="form-control"
                        defaultCountry="SE"
                        value={phone}
                        onChange={setPhone}
                        limitMaxLength={true}
                        maxLength={16}
                        placeholder={"Phone Number"}
                        autoFocus
                      />
                    </div>
                  </>
                ) : (
                  <h6>We have send otp on {phone}</h6>
                )}
  
                {toggle ? (
                  <>
                    <div className="form-group mt-3">
                      <div className="otp-container" onPaste={handlePaste}>
                        {otp.map((value, index) => (
                          <input
                            type="text"
                            class="otp-input shadow-sm"
                            key={index}
                            ref={(el) => (inputRefs.current[index] = el)}
                            value={value}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                /^\d$/.test(e.target.value)
                              ) {
                                updateOtpValue(e.target.value, index);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Backspace" && !otp[index]) {
                                focusPrevInput(index);
                              }
                            }}
                            maxLength={1}
                            style={{
                              width: "40px",
                              height: "40px",
                              textAlign: "center",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : null}
  
                {toggle ? (
                  <div className="d-flex justify-content-center my-2">
                    {timer > 0 ? (
                      <span>{`You can resend code in ${timer} seconds`}</span>
                    ) : (
                      <span
                        onClick={handleResendOtp}
                        className="ms-2 resendBtn"
                        style={{
                          cursor: canResend ? "pointer" : "not-allowed",
                          opacity: canResend ? 1 : 0.5,
                        }}
                      >
                        {resetLoading ? (
                          "sending..."
                        ) : (
                          <strong style={{ color: "#A660FF" }}>Resend OTP</strong>
                        )}
                      </span>
                    )}
                  </div>
                ) : null}
  
                <div className="mt-4 text-center">
                  <button
                    type="button"
                    onClick={toggle === true ? handleVerify : handleSendOtp}
                    className="btn btn-sm btn-primary"
                    disabled={isBtnLoading}
                  >
                    {isBtnLoading ? (
                      <CircularProgress size={"1.2rem"} sx={{ color: "#FFF" }} />
                    ) : toggle ? (
                      "Verify"
                    ) : (
                      "Send OTP"
                    )}
                  </button>
                </div>
              </div>
           }
           </div>
         </div>
 

   
      </div>
    </div>
  );
};

export default DeleteAccount;
