import { Stack } from "@mui/material";
import Footer from "../components/Footer/Footer";
import DeleteAccount from "../components/signup/DeleteAccount";

const DeleteAccountPage = () => {
  return (
    <>
        <Stack>
          <DeleteAccount />
        </Stack>
    </>
  );
};

export default DeleteAccountPage;
